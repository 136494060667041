

.btn {
  border: none;
  padding: 5px 10px;
  background: transparent;
  border-radius: 4px;
  font-family: Inter, Roobert, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  white-space: nowrap;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  &:disabled {
    background-color: #ededed;
    cursor: not-allowed; /* Adicional para indicar que o botão está desabilitado */
    color: white; /* Se quiser mudar a cor do texto também */
    border-color: transparent; /* Para remover a borda, se necessário */
  }
  
}

.title {
  font-size: 30px;
}

.back-drop {
  background-color: #191c32;
  opacity: 0.4;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.page-body {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.input {
  display: block;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #DEDEDE;
  width: max-content;
  box-sizing: border-box;
  padding: 3px 10px;
  width: 100%;
  background: #595959;
  font-family: inherit;
  span {
    color: #fff;
  }
}

.container-stream {
  .peer-video-share{
    position: relative;
  }
  
  .is-presenter {
    position: absolute;
    z-index: 10;
    max-width: 200px !important;
    top: 0;
    left: 0;
  }

  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #191B23;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
}

.meet-container {
  .peer-video {
    height: 250px;
    width: 450px;
    border-radius: 0.5rem;
    object-fit: cover;
    margin: 10px;
    border: 1px solid #191B23;
  }
  
  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #191B23;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fill-available {
  width: -webkit-fill-available;
}

.modal {
  padding: 10px;
}

.modal-small {
  min-width: 360px;
  width: 360px;
}

.modal-medium {
  min-width: 580px;
  width: 580px;
}

.border-default {
  border: solid 2px #ededed;
}

.header {
  background: #f8f2f2;
}

.container {
  background: #f8f2f2;
  border-radius: 4px;
}

body {
  background: #ffff;
 // padding: 20px;
}

.primary {
  color: #fff;
  font-weight: bold;
  background: #000;
}

.control-bar-meet {
  gap: 8px;
  display: flex;
  bottom: 0;
  padding: 15px;
  justify-content: center;
  z-index: 1;

  button{
    &:hover {
      background: #b5b5b5;
      color: #fff;
      font-weight: bold;
      border: none;
    }
  }
}

.loader {
  position: absolute;
    width: 100%;
    height: 100%;
    background: #000000c9;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.divider {
  background: #e1dada;
  border: solid 1px #e1dada;
}

.subtle {
  color: #000;
  font-weight: bold;
  &:hover {
    background: #ffffff10;
  }
}
.mono {
  font-family: monospace;
  font-weight: 600;
}

.default {
  background: rgba(173, 173, 184, 0.22);
  color: #0e0e10;
  font-weight: bold;
}

.fit-height {
  height: -webkit-fill-available;
}

.default-text {
  color: #000;
 // width: 500px;
}


.loader-dots div {
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-dots div:nth-child(1) {
	left: 8px;
	animation: loader-dots1 0.6s infinite;
}
.loader-dots div:nth-child(2) {
	left: 8px;
	animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(3) {
	left: 32px;
	animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(4) {
	left: 56px;
	animation: loader-dots3 0.6s infinite;
}
@keyframes loader-dots1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes loader-dots3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes loader-dots2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}
